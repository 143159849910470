import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, Card, Button } from 'react-bootstrap';
import CongressContext from '../CongressContext';
import Header from '../shared/Header';
import ContactForm from './ContactForm';
import icoPhone from '../../../images/icons/ico-phone.svg';
import icoMobile from '../../../images/icons/ico-mobile.svg';
import icoEmail from '../../../images/icons/ico-email.svg';
import logoPodyplomie from '../../../images/logo-podyplomie-black.svg';
import logoMagwet from '../../../images/logo-magwet.svg';
import logoMtp from '../../../images/logo-mtp.svg';
import CongressFaq from '../shared/CongressFaq';

const Contact = () => {
  const {user, congressSlug, csrf} = useContext(CongressContext);
  const {sectionSlug} = useParams();

  const buildEntries = () => (
    CongressFaq.map((entry, index) => (
      <Card key={index}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={index}>
            {entry.question}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={index}>
          <Card.Body dangerouslySetInnerHTML={{__html: entry.answer}}/>
        </Accordion.Collapse>
      </Card>
    ))
  );

  return (
    <div className="container-fluid light-bg">
      <Header congressSlug={congressSlug} bgColor="light"/>
      <div className="contact container mt-3 mt-md-5">
        <h3 className="mb-2 mb-md-5">Pomoc</h3>
        <div className="row mb-md-5 mb-3">
          <div className="col-12 col-md-3 sector mb-4 mb-md-0">
            <h6 className="mb-3">Dla uczestników</h6>
            <p>
              <small>
                Infolinia czynna poniedziałek – piątek w godz. 9:00 – 17:00 oraz w dniu kongresu od godz. 8:00 do
                zakończenia wydarzenia
              </small>
            </p>
            <p className="d-flex align-items-center">
              <img src={icoMobile} className="icon mr-2" alt="mobile"/>
              <a href="tel:801044415" className="text-black">801 044 415</a>
            </p>
            <p className="d-flex align-items-center">
              <img src={icoPhone} className="icon mr-2" alt="mobile"/>
              <a href="tel:+48224442444" className="text-black">+48 22 444 24 44</a>
            </p>
            <p className="d-flex align-items-center">
              <img src={icoEmail} className="icon mr-2" alt="email"/>
              <a href="mailto:kontakt@apdonline.pl" className="text-teal">kontakt@apdonline.pl</a>
            </p>
          </div>
          <div className="col-12 col-md-3 sector mb-4 mb-md-0">
            <h6 className="mb-3">Dla sponsorów</h6>
            <p className="d-flex align-items-center">
              <img src={icoEmail} className="icon mr-2" alt="email"/>
              <a href="mailto:reklama@medical-tribune.pl" className="text-teal">reklama@medical-tribune.pl</a>
            </p>
          </div>
          <div className="col-12 col-md-3 mb-5 mb-md-5">
            <p>Partnerem serwisu jest:</p>
            <img src={logoPodyplomie} alt="PoDyplomie" className="mb-3 d-block"/>
            <img src={logoMagwet} alt="MagWet" className="mb-3 d-block magwet-logo"/>
          </div>
        </div>
        <div className="row mt-2 mt-md-5">
          <div className="col-12 col-md-7">
            <h3 className="mb-4">Formularz kontaktowy</h3>

            <div className="mt-md-5">
              <ContactForm
                sponsorName="PoDyplomie.pl"
                policyUrl="https://podyplomie.pl/polityka-prywatnosci"
                postUrl={`/${congressSlug}/${sectionSlug}/contact`}
                user={user}
                csrf={csrf}
                congressSlug={congressSlug}
              />
            </div>
          </div>
          <div className="col-12 col-md-3 offset-md-2">
            <h3 className="mb-5">Organizator</h3>
            <img src={logoMtp} alt="Medical Tribune Polska" className="mb-3 mb-md-4"/>
            <small className="d-block mb-3">
              Medical Tribune Polska Sp. z o.o. <br/>
              ul. Powązkowska 44C
              01-797 Warszawa <br/>
              NIP: 521-008-60-45 <br/>
              <a href="https://www.podyplomie.pl" target="_blank"
                 className="my-4 d-block text-teal">www.podyplomie.pl</a>
              <a href="https://www.magwet.pl" target="_blank"
                 className="my-4 d-block text-teal">www.magwet.pl</a>
            </small>
          </div>
        </div>
        <div className="row mt-2 mt-md-5 light-grey-bg p-3 p-md-5 mb-5">
          <div className="col-12 contact--faq">
            <h3 className="mb-3">Najczęściej zadawane pytania</h3>
            <Accordion>
              {buildEntries()}
            </Accordion>
          </div>
        </div>
        &nbsp;
      </div>
    </div>
  );
}

export default Contact;
