import React, { useContext, useEffect, useState } from 'react';
import useIntervalEffect from './useIntervalEffect';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import logoAkademiaDark from '../../../images/logo-akademia-dark.svg';
import CongressContext from '../CongressContext';
import { useRootStore } from '../rootStoreProvider'
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import isMobile from '../utils/isMobile';
import Notices from '../notices/Notices';
import '../../../stylesheets/header.scss';

const Header = observer(({bgColor, congressSlug}) => {
  const {sectionSlug} = useParams();
  const {hasContests, sections, workshops, hasProducts} = useContext(CongressContext);
  const [currentWatchNowTab, setCurrentWatchNowTab] = useState('appointments')

  const store = useRootStore();
  useEffect(() => {
    store.appointmentsStore.init(sections, sectionSlug, congressSlug)
    store.workshopsStore.init(workshops)
    setCurrentWatchNowTab(store.appointmentsStore.watchNow() ? 'appointments' : 'workshops')
  }, []);

  useIntervalEffect(() => {
    store.appointmentsStore.setAppointment();
    store.workshopsStore.setCurrentWorkshops();
    setCurrentWatchNowTab(store.appointmentsStore.watchNow() ? 'appointments' : 'workshops');
  }, 1000 * 60);

  const menuProps = {
    watchNow: store.appointmentsStore.watchNow() || store.workshopsStore.watchNow(),
    hasCurrentAppointments: store.appointmentsStore.watchNow(),
    currentAppointment: store.appointmentsStore.currentAppointment,
    hasWorkshops: !isEmpty(workshops),
    hasCurrentWorkshops: !isEmpty(store.workshopsStore.currentWorkshops),
    currentWatchNowTab,
    hasContests,
    congressSlug,
    setCurrentWatchNowTab,
    hasProducts,
  }

  return (
    <header className={clsx('pb-3 mb-lg-0', bgColor === 'light' && 'light-bg', bgColor === 'dark' && 'bg-black')}>
      <div className="container d-flex justify-content-between px-md-0 px-3 pt-2 position-relative">
        <Link to={`/${congressSlug}/${sectionSlug}`} className="logo-link" data-kongres="congressSlug">
          <img src={logoAkademiaDark} alt="Akademia Po Dyplomie" className="mt-1 logo-img"/>
        </Link>
        {isMobile() ? <MobileMenu {...menuProps}/> : <DesktopMenu {...menuProps}/>}
      </div>
      <Notices />
    </header>
  );
})

Header.defaultProps = {
  bgColor: null
}

Header.propTypes = {
  congressSlug: PropTypes.string,
  bgColor: PropTypes.string
};

export default Header;
