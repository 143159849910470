import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isEmpty, values } from 'lodash';
import 'dayjs/locale/pl';
import CongressContext from '../CongressContext';
import Header from '../shared/Header';
import useVideoBackgroundEffect from '../shared/useVideoBackgroundEffect';
import HorizontalSponsors from '../sponsors/HorizontalSponsors';
import apiFetch from '../utils/apiFetch';
import Products from './Products';
import '../../../stylesheets/marketplace.scss';

const MarketPlace = () => {

  useVideoBackgroundEffect();
  const {congressSlug} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const [hasError, setError] = useState(false);
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    apiFetch(`${congressSlug}/${sectionSlug}/marketplace/`, setError, setProductsList);
  }, []);

  const buildProductsRow = () => {
    if (isEmpty(productsList)) return false;

    return productsList.map(entry => {
      return (<div className="container bg-white marketplace--divider pt-4" key={entry.sponsor.slug}>
        <ul className="list-unstyled d-flex flex-columns flex-wrap justify-content-lg-center px-5">
          <Products products={entry.products} />

          <li className="mx-4 d-flex flex-column text-center justify-content-between flex-even mb-4">
            <figure className="align-self-center flex-fill">
              <img src={entry.sponsor.avatar} alt={entry.sponsor.name} className="marketplace--logo-img mx-auto mx-lg-0"/>
            </figure>
            <div className="link-wrapper">
              <Link to={`/${congressSlug}/${sectionSlug}/sponsors/${entry.sponsor.slug}`}
                    className="btn d-inline-block text-orange-500 px-lg-4">
                <i className="fa fa-chevron-right d-block py-4 text-orange-500"/>
                Zobacz więcej</Link>
            </div>
          </li>
        </ul>
      </div>)
    });
  }

  return (
    <Fragment>
      <Header congressSlug={congressSlug} bgColor="light"/>
      <div className="marketplace mt-lg-4 pt-lg-4 mb-lg-4 text-black">
        <div className="container bg-white py-4">
          <h1 className="h2 text-center">Strefa zakupów</h1>
        </div>
        {buildProductsRow()}
      </div>
      <HorizontalSponsors/>
    </Fragment>
  );
}

export default MarketPlace;
