import React, { Fragment, useContext, useState, useEffect } from 'react';
import CongressContext from '../CongressContext';
import Header from '../shared/Header';
import HorizontalSponsors from './HorizontalSponsors';
import { useParams } from 'react-router-dom';
import apiFetch from '../utils/apiFetch';
import Rank from './Rank';
import useVideoBackgroundEffect from '../shared/useVideoBackgroundEffect';
import currentCongressDay from '../utils/currentCongressDay';
import '../../../stylesheets/sponsors.scss';
const RANKS = ['platynowy', 'złoty', 'srebrny', 'sponsor'];

const Sponsors = () => {
  useVideoBackgroundEffect('/video/background.mp4', '/video/background.jpg');
  const {congressSlug, sections} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const section = sections[sectionSlug]
  const [hasError, setError] = useState(false);
  const [sponsorList, setSponsorList] = useState({});
  const congressDay = currentCongressDay(section.congress_days);

  useEffect(() => {
    apiFetch(`${congressSlug}/${sectionSlug}/sponsors`, setError, setSponsorList);
  }, []);

  const buildSponsors = () => (
    RANKS.map((rank, index) => {
      return <Rank group={index} sponsors={sponsorList[congressDay]} key={index} rankId={index} title={rank}/>;
    })
  )

  return (
    <Fragment>
      <Header congressSlug={congressSlug}/>
      <div className="container sponsors">
        <h1 className="text-center text-white">Odwiedź stoiska wystawców</h1>
        <div className="d-flex flex-row flex-lg-columns justify-content-center flex-wrap">
          {buildSponsors()}
        </div>
      </div>
      <HorizontalSponsors/>
    </Fragment>
  );
}

export default Sponsors;
