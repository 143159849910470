// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();

import 'bootstrap';
import 'jquery.easing';
import 'slick-carousel';
import '../src/scroll-nav';
import '../src/jquery.checkall';
import '../src/helpers';
import '../src/professional_group';
import '../src/carousel';
import findCurrentAppointment from '../src/current_appointment';
import carouselSettings from '../src/sponsor_carousels';
import ahoy from 'ahoy.js';

import { summitCountDown } from "../src/countdown";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:

const componentRequireContext = require.context('../src/components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

window.$ = $;
window.jQuery = jQuery;
window.carouselSettings = carouselSettings;

// stylesheets
import '../src/font-awesome.js';

$(document).ready(() => {
  const defaultToggleText = 'Pokaż więcej';

  $('#toggle_description').click(event => {
    event.preventDefault();
    $('#description').slideToggle({
      easing: 'easeInExpo'
    });
    event.target.text = event.target.text === defaultToggleText ? 'Zwiń' : defaultToggleText;
  });

  findCurrentAppointment();
  setInterval(findCurrentAppointment, 60000);
  if (window.screen.width > 776) {
    $('#agenda .buttons .btn').addClass('btn-lg');
  }

  $('.checkall').checkall();

  if ($('#congress-days-buttons .congress-day-links').length) {
    $('#congress-days-buttons .congress-day-links').click(function (event) {
      event.preventDefault();
      const currentDay = $(this).data('congress-day');

      // toggle sponsors
      $('#all-sponsors .sponsors-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $('#partners-carousel .sponsors-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $(`#sponsors-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
      $(`#sponsors-carousel-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
      //
      //toggle days buttons
      $('#congress-days-buttons .congress-day-links.btn-primary').toggleClass('btn-primary').toggleClass('btn-outline-primary');
      $(`#congress_day_link-${currentDay}`).toggleClass('btn-primary').toggleClass('btn-outline-primary');
      //
      // //toggle agenda
      $('#all-appointments .appointments-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $('.agenda--wrapper .appointments-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $(`#appointments-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
    })
  }

  const minSponsorsAmountForSlider = 3;
  if ($('.logos').length > minSponsorsAmountForSlider) {
    $('.slide-track').slick({
      dots: false,
      draggable: false,
      arrows: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autookaySpeed: 3000,
      cssEase: 'linear',
      variableWidth: true
    });
  }

  if ($('#countdown').length) {
    summitCountDown()
  }


  $('.nav__button').on('click', function(e) {
    $('body').toggleClass('menu--active');
    $('.nav__menu').toggleClass('nav__menu--active');
  });

  $(window).resize(function() {
    $('body').removeClass('menu--active');
    $('.nav__menu').removeClass('nav__menu--active');
  });
});

$(document).on('mouseover', '[data-toggle="popover-hover"]', function(e) {
  $(e.target).popover({
    html: true,
    trigger: 'hover manual',
    placement: 'auto',
    content: function () { return '<img src="' + $(this).data('img') + '" alt="" class="img-fluid" />'; }
  });
});

$(document).on('ajax:send', '#new_congresses_sponsors_message', function(e) {
  Helpers.appendIndicator($(e.target));
});

$(document).on('ajax:success', '#new_congresses_sponsors_message', function(e) {
  var data = event.detail[0];
  $('.message').html(data['html'])
});

$(document).on('ajax:error', '#new_congresses_sponsors_message', function(e) {
  Helpers.removeIndicator($(e.target))
});

$(document).on('click', 'a.sponsor-product-link', function(e) {
  var track_data = $(e.target).closest('a').data('track').split('/')
  ahoy.track('sponsor_product_click', {
    product_id: parseInt(track_data[0]),
    sponsor_id: parseInt(track_data[1]),
    congress_slug: track_data[2],
    section_slug: track_data[3],
    on_marketplace: (track_data[4] != undefined)
  });
});

const images = require.context('../images', true)
