import React, { Fragment, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import CongressContext from '../CongressContext';
import { isEmpty } from 'lodash';
import Header from "../shared/Header";
import ContactForm from './ContactForm';
import Calendar from './Calendar';
import Attachments from './Attachments';
import Links from './Links';
import Ads from '../shared/Ads';
import useVideoBackgroundEffect from '../shared/useVideoBackgroundEffect';
import Video from './Video';
import Player from "./Player";
import Products from './Products';
import '../../../stylesheets/sponsor.scss'

const SponsorMobile = ({sponsorDetails, styles, textStyles}) => {
  useVideoBackgroundEffect('', '');
  const {user, congressSlug, csrf} = useContext(CongressContext);
  const {sectionSlug, sponsorSlug} = useParams();

  return (
    <Fragment>
      {styles}
      <div className="sponsor d-flex flex-row">
        <div className="sponsor--container container">
          <Header congressSlug={congressSlug}/>
          <div className="row">
            <div className="col-12">
              {sponsorDetails.sponsor.player_embed &&
              <Player sponsorDetails={sponsorDetails} textStyles={textStyles} congressSlug={congressSlug}/>}

              <Ads mobileId={94540} className="my-4"/>

              {sponsorDetails.sponsor.video_embed &&
              <Video sponsorDetails={sponsorDetails} textStyles={textStyles} congressSlug={congressSlug}/>}

              {!isEmpty(sponsorDetails.attachments) &&
              <Attachments attachments={sponsorDetails.attachments} sponsor={sponsorDetails.sponsor}/>}

              {!isEmpty(sponsorDetails.products) &&
              <Products products={sponsorDetails.products} sponsor={sponsorDetails.sponsor}/>}

              {!isEmpty(sponsorDetails.links) && <Links
                links={sponsorDetails.links}
                sponsor={sponsorDetails.sponsor}
                congressSlug={congressSlug}
                sectionSlug={sectionSlug}
              />}

              <Ads mobileId={94542} className="my-4"/>

              {!isEmpty(sponsorDetails.sponsor.calendar_url) && <Calendar sponsorDetails={sponsorDetails} textStyles={textStyles}/>}

              {!isEmpty(sponsorDetails.sponsor.contact_emails) &&
              <div style={textStyles} className="p-4" id="contact-form">
                <ContactForm
                  textStyles={textStyles}
                  sponsorName={sponsorDetails.sponsor.name}
                  policyUrl={sponsorDetails.sponsor.policy_url}
                  postUrl={`/${congressSlug}/${sectionSlug}/sponsor/${sponsorSlug}/message`}
                  user={user}
                  csrf={csrf}
                  congressSlug={congressSlug}
                />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SponsorMobile;
