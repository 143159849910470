import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ahoy from 'ahoy.js';
import { motion } from 'framer-motion';

const Links = ({links, sponsor, congressSlug, sectionSlug}) => {
  if (isEmpty(links)) return null;

  const event_sponsor_link_click = (link_id) => {
    ahoy.track('sponsor_link_click', {
      link_id: link_id,
      sponsor_id: sponsor.id,
      section_slug: sectionSlug,
      congress_slug: congressSlug
    });
  }
  const makeList = () => (
    links.map(link =>
      <li
        className="sponsor--links text-black text-center pb-lg-3 mb-4 mx-3 px-4 py-4 d-flex flex-column justify-content-between"
        key={link.id}>
        <a onClick={() => event_sponsor_link_click(link.id)} href={link.url} target="_attachment"
           className="text-black font-bold d-block"
           data-kongres={congressSlug}
           data-sponsor={sponsor.name}>{link.name}</a>

        <a onClick={() => event_sponsor_link_click(link.id)} href={link.url} target="_attachment" className="d-block text-orange-500">Sprawdź</a>
      </li>
    ));

  return (<motion.div
      layout
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <div className="sponsor--check-more text-white">
        <h4 className="mb-4 text-center">Odwiedź strony</h4>
        <ul
          className="list-unstyled mx-lg-5 d-flex flex-lg-row flex-column flex-wrap justify-content-center align-items-stretch">
          {makeList()}
        </ul>
      </div>
    </motion.div>
  )
}

export default Links;
