import React, { useState, useEffect } from 'react';
import apiFetch from '../utils/apiFetch';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import dots from '../../../images/icons/dots.svg';
import play from '../../../images/icons/play.svg';
import WatchNowPreloader from './WatchNowPreloader';
import formatDate from '../utils/formatDate';
import dayjs from 'dayjs';

const WatchNowMenu = ({
                        congressSlug,
                        hasCurrentWorkshops,
                        hasCurrentAppointments,
                        currentWatchNowTab,
                        currentAppointment,
                        setCurrentWatchNowTab,
                        switchOff,
                        sectionSlug,
                      }) => {
  const [currentAppointmentsError, setCurrentAppointmentsError] = useState(false);
  const [currentAppointments, setCurrentAppointments] = useState({});
  const today = dayjs().format('YYYY-MM-DD');

  useEffect(() => {
    apiFetch(`${congressSlug}/current_appointments`, setCurrentAppointmentsError, setPayload);
  }, []);

  const setPayload = payload => {
    if (isEmpty(payload['appointments'])) {
      payload['appointments'] = [currentAppointment]
    }
    setCurrentAppointments(payload)
  }

  const buildEntries = () => currentWatchNowTab === 'appointments' ? buildAppointments() : buildWorkshops();

  const buildAppointments = () => {

    return isEmpty(currentAppointments?.appointments) ?
      appointmentItem(currentAppointment)
      :
      currentAppointments?.appointments.map(appointment => appointmentItem(appointment))
  }

  const appointmentItem = appointment => (
    <li className="pr-4 flex-fill d-flex flex-column justify-content-between" key={appointment.id}>
      <p>
        {appointment.title}
      </p>
      <p
        className="text-nowrap font-weight-lighter d-block mt-2">{formatDate([today, `${appointment.start_time}`].join('T'), 'HH:mm')} - {formatDate([today, `${appointment.finish_time}`].join('T'), 'HH:mm')}</p>
      <p>
        <a className="btn btn-orange btn-block text-white"
           href={`/${congressSlug}/${appointment.section_slug || sectionSlug}/appointment`} onClick={() => switchOff(false)}>
          <img src={play} className="play mr-2 pb-1" alt={appointment.title}/>
          Oglądaj</a>
      </p>
    </li>
  )

  const buildWorkshops = () => {
    return currentAppointments?.workshops.map(workshop => {
      return (<li className="pr-4 flex-fill d-flex flex-column justify-content-between" key={workshop.id}>
        <p>
          {workshop.title}
        </p>
        <p
          className="text-nowrap time d-block mt-2">{formatDate(workshop.start_time, 'HH:mm')} - {formatDate(workshop.finish_time, 'HH:mm')}</p>
        <p>
          <a className="btn btn-orange btn-block text-white"
             href={`/${congressSlug}/${sectionSlug}/workshops/${workshop.slug}`}>
            <img src={play} className="play mr-2 pb-1" alt={workshop.title}/>
            Oglądaj</a>
        </p>
      </li>)
    })
  }

  return (<div className="watch-now--appointments px-4 py-3">

    <ul className="list-unstyled">
      <li className="nav-item-horizontal">
        <ul className="list-unstyled d-flex justify-content-between px-4 mt-2">
          <li onClick={() => setCurrentWatchNowTab('appointments')}
              className={clsx("flex-fill pb-2",
                currentWatchNowTab === 'appointments' && 'active',
                !hasCurrentAppointments && 'd-none')}
          >
            Sesje
            <span className="live d-inline-block"><img src={dots} alt="." className="dark ml-2 pb-1"/> na żywo</span>
          </li>
          <li onClick={() => setCurrentWatchNowTab('workshops')}
              className={clsx("flex-fill pl-3 pb-2",
                currentWatchNowTab === 'workshops' && 'active',
                !hasCurrentWorkshops && 'd-none')
              }
          >
            Warsztaty
            <span className="live d-inline-block"><img src={dots} alt="." className="dark ml-2 pb-1"/> na żywo</span>
          </li>
        </ul>
      </li>
    </ul>

    <ul className="list-unstyled d-flex flex-md-row flex-column">
      {isEmpty(currentAppointments[currentWatchNowTab]) ? <>
        <li><WatchNowPreloader/></li>
        <li><WatchNowPreloader/></li>
      </> : buildEntries()
      }
    </ul>
  </div>);
}

export default WatchNowMenu;
