import React, { Fragment, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import CongressContext from '../CongressContext';
import Header from '../shared/Header';
import HorizontalSponsors from '../sponsors/HorizontalSponsors';
import IconSponsors from '../../../images/icons/ico-sponsors.svg';
import Sections from '../shared/Sections';
import Ads from '../shared/Ads';
import Appointments from './Appointments';
import Workshops from './Workshops';
import CongressDays from './CongressDays';
import WorkshopDays from './WorkshopDays';
import { observer } from 'mobx-react-lite';
import { useAgendaStore } from '../rootStoreProvider';

const Agenda = observer(() => {
  const {congress, congressSlug} = useContext(CongressContext);
  const {sectionSlug} = useParams()
  const store = useAgendaStore();

  useEffect(() => {store.setCongressDay(store.rootStore.appointmentsStore.currentDay)}, [])

  return (
    <Fragment>
      <Header congressSlug={congressSlug} bgColor="light"/>
      <div className="container-fluid light-bg">
        <div className="appointments">
          <div className="container px-md-5">
            <h1 className="text-center py-md-5">Program <span className="light-grey-text">{congress.title}</span></h1>
            <div
              className="congress-days p-md-4 px-3 pt-3 pb-0 pb-md-0 mb-md-5 d-flex flex-column justify-content-between">
              <ul className="list-unstyled d-flex flex-row mb-0 pb-4">
                <CongressDays agendaStore={store}/>
                {!isEmpty(store.rootStore.workshopsStore.workshops) && <WorkshopDays agendaStore={store}/>}
              </ul>
              <Sections className={'btn btn-success'} urlSuffix={'agenda'} showWorkshops={false}/>
            </div>
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="appointments--agenda mt-3">
                  {store.congressDay === 'workshop' ?
                    <Workshops/>
                    :
                    <Appointments congressDay={store.congressDay}/>
                  }
                </div>
              </div>
              <div className="d-none d-md-block col-md-4">
                <aside
                  className="agenda--sponsors vw-25 d-none d-md-flex justify-content-between align-items-start px-4 py-4 mb-3">
                  <div>
                    <Link className="navigation-link active h4"
                          to={`/${congressSlug}/${sectionSlug}/sponsors`}>Wystawcy</Link>
                    <Link className="btn btn-outline-success d-block mt-3"
                          to={`/${congressSlug}/${sectionSlug}/sponsors`}>Odwiedź
                      stoiska</Link>
                  </div>
                  <img src={IconSponsors} alt="Wystawcy" className="ico-sponsors"/>
                </aside>
                <Ads className="ml-3 mt-2" desktopId={94537}/>
              </div>
            </div>
          </div>
        </div>
        <Ads className="my-3 mx-auto" mobileId={94542}/>
        <HorizontalSponsors/>
      </div>
    </Fragment>
  );
})

export default Agenda;
