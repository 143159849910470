import React from 'react'
import { makeObservable, onBecomeObserved, onBecomeUnobserved, flow, action, runInAction, observable } from 'mobx'
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { isEmpty, first, last, filter, sortBy, valuesIn, find } from 'lodash';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

export class AppointmentsStore {
  rootStore
  congressSlug
  congressDays
  appointments
  sections
  section
  date = dayjs()
  currentDay
  currentDayAppointments
  currentAppointment
  stickyAppointment
  currentCongressTab
  lastEvent = false
  lastAppointmentMargin = 180

  constructor(rootStore) {
    makeObservable(this, {
      currentDay: observable,
      congressDays: observable,
      currentAppointment: observable,
      stickyAppointment: observable,
      currentCongressTab: observable,
      setSectionInfo: action,
      setCurrentCongressTab: action,
      init: action
    });

    this.rootStore = rootStore;
  }

  init(sections, sectionSlug, congressSlug) {
    this.congressSlug = congressSlug;
    this.loadSections(sections);
    this.setSectionInfo(sectionSlug);
    this.setCurrentCongressTab(sectionSlug);
  }

  loadSections(sections) {
    this.sections = sections;
  }

  setSectionInfo(slug) {
    runInAction(() => {
      this.section = this.sections[slug]
      this.congressDays = sortBy(valuesIn(this.section.congress_days), [day => day.event_date]);
      this.appointments = this.section.appointments;
      this.setAppointment();
    })
  }

  setCurrentCongressTab(slug) {
    if (slug !== 'workshops') {
      this.setSectionInfo(slug)
    }
    this.currentCongressTab = slug
  }

  setAppointment() {
    this.date = dayjs()
    runInAction(() => {
      this.currentDay = filter(this.congressDays, congressDay => this.date.isSameOrBefore(this.finishTimeOfLastAppointmentFor(congressDay), 'minutes'))

      if (this.eventIsFinished()) {
        this.currentDay = last(this.congressDays);
        this.currentAppointment = last(this.appointments[this.currentDay?.id]) || false
        this.lastEvent = false;
      } else {
        this.currentDay = first(this.currentDay);
        this.currentDayAppointments = this.appointments[this.currentDay.id]
        this.fetchStickyAppointment();
        this.currentAppointment = this.findCurrentAppointment()
      }
    })
    if (isEmpty(this.rootStore.agendaStore.congressDay)) {
      this.rootStore.agendaStore.setCongressDay(this.currentDay)
    }
  }

  finishTimeOfLastAppointmentFor(congressDay) {
    return dayjs(`${congressDay.event_date}T${last(this.appointments[congressDay.id])?.finish_time}`).add(this.lastAppointmentMargin, 'minute')
  }

  findCurrentAppointment() {
    if (!isEmpty(this.stickyAppointment)) return this.stickyAppointment;

    let appointment = find(this.currentDayAppointments, app => this.date.isSameOrBefore(`${this.currentDay.event_date}T${app.finish_time}`, 'minutes'))
    if (isEmpty(appointment)) appointment = this.handleLastAppointment()

    return appointment;
  }

  watchNow() {
    if (isEmpty(this.currentAppointment)) return false;
    if (this.lastEvent) return true;
    
    return this.date.isBetween(
      `${this.currentDay.event_date}T${this.currentAppointment.start_time}`,
      `${this.currentDay.event_date}T${this.currentAppointment.finish_time}`,
      'minutes',
      '[]');
  }

  handleLastAppointment() {
    this.lastEvent = true;
    let appointment = last(this.currentDayAppointments);

    if (this.date.diff(`${this.currentDay.event_date}T${appointment.finish_time}`, 'minute') >= this.lastAppointmentMargin) {
      const nextCongressDay = find(this.congressDays, congressDay => this.date.isBefore(congressDay.event_date, 'day'))

      if (!isEmpty(nextCongressDay)) {
        this.currentDay = nextCongressDay;
        appointment = first(this.appointments[this.currentDay.id])
      }
      this.lastEvent = false;
    }
    return appointment;
  }

  eventIsFinished() {
    return isEmpty(this.currentDay)
  }

  isBefore() {
    if (isEmpty(this.currentDay)) return false;

    return this.date.isSameOrBefore(`${this.currentDay.event_date}T${this.currentAppointment.start_time}`, 'minute')
  }

  fetchStickyAppointment = flow(function* () {
    fetch(`/api/v1/${this.congressSlug}/${this.section?.details?.slug}/sticky_appointment`)
      .then(response => response.json())
      .then(data => {
        this.stickyAppointment = data;
        this.currentAppointment = (+data?.id > 0) ? data : this.findCurrentAppointment();
      })
  })
}
