import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import CongressContext from '../CongressContext';
import useIntervalEffect from '../shared/useIntervalEffect';
import apiFetch from "../utils/apiFetch";

const Notices = () => {
  const {congressSlug} = useContext(CongressContext);
  const {sectionSlug, sponsorSlug} = useParams();
  const [hasError, setError] = useState(false);
  const [currentNotice, setcurrentNotice] = useState([]);
  const fetchNotices = () => {
    apiFetch(`${congressSlug}/${sectionSlug}/notices/`, setError, setcurrentNotice);
  }
  const isSponsorPage = (window.location.pathname === `/${congressSlug}/${sectionSlug}/sponsors/${sponsorSlug}`)

  useIntervalEffect(fetchNotices, 1000 * 60);
  useEffect(fetchNotices, []);

  if (isEmpty(currentNotice)) return null;
  if (isSponsorPage && !currentNotice.visible_for_sponsors) return null;

  return (
    <Fragment>
      <section className="notices" style={{color: currentNotice.body_color, backgroundColor: currentNotice.body_background}}>
        <div className="container px-3 py-2 px-md-0 d-flex justify-content-between align-items-md-center my-3 flex-column flex-md-row">
          <div className="notice--message m-0 align-items-center">
            {currentNotice.body}
          </div>
          {currentNotice.button_name &&
            <a href={currentNotice.button_url}
               className="d-block btn btn-default flex-shrink-1 text-nowrap ml-md-3 ml-0 py-2 px-4 my-3 my-md-0"
               target="_blank"
               style={{color: currentNotice.button_color, backgroundColor: currentNotice.button_background}}
            >{currentNotice.button_name}</a>}
        </div>
      </section>
    </Fragment>
  );
}

export default Notices;
