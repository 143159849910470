import React, { useContext, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import icoDocument from '../../../images/icons/ico-document.svg'
import { apiPost } from '../utils/apiFetch';
import CongressContext from '../CongressContext';


const Attachments = ({attachments, sponsor}) => {
  if (isEmpty(attachments)) return null;

  const {congressSlug, sectionSlug, csrf} = useContext(CongressContext);
  const [hasError, setError] = useState(false);
  const [response, setResponse] = useState({status: null});
  const postUrl = `/${congressSlug}/${sectionSlug}/sponsor/${sponsor.slug}/send_attachments`;
  const buttonText = () => {
    let text = '';
    switch (response.status) {
      case 'sent':
      case 'limit':
        text = response.result;
        break;
      case 'working':
        text = 'Chwileczkę...Wysyłam...';
        break;
      default:
        text = 'Wyślij wszystkie materiały na email';
    }
    return text;
  }

  const sendAttachments = () => {
    setResponse({status: 'working'})
    apiPost(postUrl, csrf, [], setError, setResponse);
  }

  const quantity = () => attachments.length;
  const styleType = () => {
    let css = 'more';
    switch (quantity()) {
      case 1:
        css = 'one';
        break;
      case 2:
        css = 'two';
        break;
    }
    return css;
  };

  const makeList = () => (
    attachments.map(attachment => <li
      className={clsx('sponsor--attachment d-flex align-items-center pb-3 mb-3 mx-2', styleType())}
      key={attachment.id}>
      <div className="icon-wrapper mr-4">
        {attachment.thumbnail ?
          <img src={attachment.thumbnail} className="attachment--thumbnail" alt={attachment.title}
               data-toggle="popover-hover" data-img={attachment.thumbnail_big}/> :
          <img src={icoDocument} className="sponsor--icon" alt={attachment.title}/>
        }
      </div>
      <div className="context-wrapper pb-3">
        <a href={attachment.url} target="_blank">{attachment.title}</a>
        <small>{attachment.description} ({attachment.type}, {attachment.size})</small>
      </div>
    </li>));

  return (<motion.div
    layout
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
  >
    <div className="sponsor--check-more text-white mt-4 mt-lg-1">
      <div className="mb-4 d-flex flex-row justify-content-between">
        <h4>Pobierz materiały</h4>
        <button className="btn btn-orange text-white" disabled={response.status === 'working' && 'disabled'}
                onClick={() => sendAttachments()}>{buttonText()}</button>
      </div>
      <ul className={clsx('sponsor--attachments list-unstyled d-flex flex-lg-row flex-column flex-wrap align-items-left align-items-lg-center ', styleType())}>
        {makeList()}
      </ul>
    </div>
  </motion.div>)
}

export default Attachments;
