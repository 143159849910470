import { useEffect } from 'react';

const useVideoBackgroundEffect = (videoSrc = '', posterSrc = '') => (
  useEffect(() => {
    const background = document.getElementById('bgvid');
    background.src = videoSrc;
    background.poster = posterSrc;

    return () => {
      background.src = '/video/background-hub.mp4';
    }
  })
)

export default useVideoBackgroundEffect;
